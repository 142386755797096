@media (prefers-reduced-motion: no-preference) {
  .pulse {
    position: relative;
  }

  .pulse::before {
    animation: badge-pulse 2s infinite cubic-bezier(0.16, 1, 0.3, 1);
    border: solid 2px #fff;
    border-radius: 50%;
    box-sizing: content-box;
    content: "";
    height: 10px;
    left: -4px;
    opacity: 0;
    position: absolute;
    top: -4px;
    width: 10px;
  }

  @keyframes badge-pulse {
    from {
      opacity: 1;
      transform: scale(0.43);
    }
    to {
      opacity: 0;
      transform: scale(1);
    }
  }
}
