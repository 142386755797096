
@media (prefers-reduced-motion: no-preference) {
  .textShimmer {
    --text-shimmer: linear-gradient(270deg, rgb(35 42 49) 0%, #C938E3 45%, #4941FB 55%, rgb(35 42 49) 100%) 0 0 / 440% 100%;
    background: var(--text-shimmer);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: move-text-shimmer 8s 0s cubic-bezier(0.37, 0, 0.63, 1);
    animation-fill-mode: forwards;
    animation-iteration-count: 2;
  }

  @keyframes move-text-shimmer {
    to {
      background-position: -400% 0;
    }
  }

  .textMaskReveal {
    color: #0000;
    background: 
    linear-gradient(#fcfbfb 0 0) no-repeat,
    linear-gradient(#000 0 0) no-repeat;
    background-size: 0% 100%;
    -webkit-background-clip: padding-box,text;
            background-clip: padding-box,text;

  }
  .maskAnim .reveal1 span {
    animation: 
    maskReveal .4s 0s both,
    textReveal .4s .2s both;
  }

  .maskAnim .reveal2 span {
    animation:
    maskReveal .4s .3s both,
    textReveal .4s .5s both;
  }

  .maskAnim .reveal3 span {
    animation:
    maskReveal .4s .6s both,
    textReveal .4s .8s both;
  }

  .maskAnim .reveal4 span {
    animation:
    maskReveal .4s .9s both,
    textReveal .4s 1.1s both;
  }

  .maskAnim::before {
    animation:
    maskBlockReveal 3s .2s both;
    bottom: -100%;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform 3s cubic-bezier(0.12, 0, 0.39, 0);
    z-index: 1;
  }

  @keyframes maskReveal {
    to { background-size: 150% 100% }
  }
  
  @keyframes textReveal {
    to { background-position:-200% 0,0 0 } 
  }

  @keyframes maskBlockReveal {
    to {  transform: translateY(100%);
    } 
  }

  .maskAnimWrapOpen {
    transition: max-height 0.4s 0s cubic-bezier(0.12, 0, 0.39, 0);
  }

  .shadowPulse::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-shadow: 0px 0px 12px 0px rgba(201, 56, 227, 0.30);
    border-radius: 12px;
    opacity: 0;
  }

  .shadowPulseAnim::before {
    animation: fadeInOut 1s 1.4s both;
  }

  @keyframes fadeInOut {
    0% { opacity: 0; }
    50% { opacity: 100%; }
    100% { opacity: 0; }
  }

  @media (prefers-color-scheme: dark) {
    [data-mode="auto"] .textShimmer {
      --text-shimmer: linear-gradient(270deg, #d0cdcd 0%, #C938E3 45%, #4941FB 55%, #d0cdcd 100%) 0 0 / 400% 100%;
    }

    [data-mode="auto"] .textMaskReveal {
      color: #d0cdcd00;
      background:
      linear-gradient(#1d2228 0 0) no-repeat,
      linear-gradient(#d0cdcd 0 0) no-repeat;
      background-size: 0% 100%;
      -webkit-background-clip: padding-box,text;
              background-clip: padding-box,text;
    }
  }
}
