/* style to be used with placeholders for animated shimmer effect */
@media (prefers-reduced-motion: no-preference) {
  .shimmer {
    overflow: hidden;
    position: relative;
  }

  .shimmer::before {
    content: "";
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholder-shimmer;
    animation-timing-function: linear;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  @keyframes placeholder-shimmer {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }
}

/* slimbar animation fade in/out with motion safe */
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

/* Apply animations only if the user's preference is not set to 'reduce motion' */
@media (prefers-reduced-motion: no-preference) {
  .fade-in {
    animation: fadeIn ease 1s;
    z-index: 2;
  }

  .fade-out {
    animation: fadeOut ease 1s;
    z-index: 2;
  }
}

/* Reduce animations if the user's preference is set to 'reduce motion' */
@media (prefers-reduced-motion: reduce) {
  .fade-in,
  .fade-out {
    animation: none;
  }
}